/**
 * 客户端websocket
 * @constructor
 */
export var WS = function () {}

WS.prototype = {
  wsUrl: '',
  socket: null,
  retryTimes: 0,
  status: 0, // 0:ws未连接，1：ws连接成功，2：客户接入成功，3：坐席分配成功，4：会话断开，5：ws断开
  sessionInfo: {
    sessionId: '',
    agentId: ''
  },
  userInfo: {
    clientId: '',
    clientType: 'h5',
    clientIp: '',
    clientIpRegion: '',
    clientOs: '',
    clientBrowse: '',
    sourceSite: '',
    userId: '',
    userName: '',
    userHeadImg: '',
    userGroup: '',
    lastServiceAgentId: 0
  },
  commonFiled: {
    appId: '',
    appName: '',
    orgId: 0,
    version: 1,
    msgType: 0,
    timestamp: 0
  },
  msgType: {
    heartMsg: 1, // 心跳消息
    custAccessMsg: 2, // 客户接入消息
    waitMsg: 3, //客户排队对待消息
    agentAccessMsg: 4, // 坐席分配成功消息
    chatMsg: 5, // 聊天消息
    responseMsg: 6, // 聊天消息应答
    agentStatusChangeMsg: 7, // 坐席状态改变
    agentChangeMsg: 8, // 坐席转接
    sessionOverMsg: 9, // 会话结束
    accessResultMsg: 10, //接入结果应答
    commentMsg: 11, // 评论消息
    notInServiceTimeMsg: 12 // 不在服务时间范围内
  },
  event: {
    connectSuccess: function () {},
    connectClose: function () {},
    heartbeat: function () {},
    accessSuccess: function () {},
    chatMsgReceived: function () {},
    waitMsgReceived: function () {},
    agentAccessMsgReceived: function () {},
    sessionOverMsgReceived: function () {},
    responseMsgReceived: function () {},
    commentMsgReceived: function () {},
    agentChangeMsgReceived: function () {}
  },
  heartTimer: null, // 心跳定时器
  /**
   * 获取共通字段
   * @param msgType
   * @returns {any}
   */
  getCommonFiled(msgType) {
    var commonFiled = JSON.parse(JSON.stringify(this.commonFiled))
    commonFiled.msgType = msgType
    commonFiled.timestamp = new Date().getTime()
    return commonFiled
  },
  /**
   * 初始化
   * @param opts
   */
  init: function (opts) {
    // 初始化
    var _this = this
    var ef = function () {}
    var url = opts.url
    var appId = opts.appId
    var appName = opts.appName
    var orgId = opts.orgId
    var userType = opts.userType || 1
    var userId = opts.userId || new Date().getTime()
    var userName = opts.userName || '匿名'
    var userHeadImg = opts.userHeadImg || 'default'
    var userGroup = opts.userGroup
    var clientId = opts.clientId || new Date().getTime()
    var clientType = opts.clientType || 'h5'
    var clientIp = opts.clientIp || ''
    var clientIpRegion = opts.clientIpRegion || ''
    var clientOs = opts.clientOs || ''
    var clientBrowse = opts.clientBrowse || ''
    var online = opts.online || ''
    var sourceSite = opts.sourceSite || ''
    var lastServiceAgentId = opts.lastServiceAgentId || 0
    var connectSuccess = opts.connectSuccess || ef
    var connectClose = opts.connectClose || ef
    var connectRetry = opts.connectRetry || ef
    var heartbeat = opts.heartbeat || ef
    var accessSuccess = opts.accessSuccess || ef
    var chatMsgReceived = opts.chatMsgReceived || ef
    var waitMsgReceived = opts.waitMsgReceived || ef
    var agentAccessMsgReceived = opts.agentAccessMsgReceived || ef
    var sessionOverMsgReceived = opts.sessionOverMsgReceived || ef
    var responseMsgReceived = opts.responseMsgReceived || ef
    var commentMsgReceived = opts.commentMsgReceived || ef
    var agentChangeMsgReceived = opts.agentChangeMsgReceived || ef
    _this.retryTimes = 0
    _this.wsUrl = url
    _this.commonFiled.appId = appId
    _this.commonFiled.appName = appName
    _this.commonFiled.orgId = orgId
    _this.userInfo.userType = userType
    _this.userInfo.userId = userId
    _this.userInfo.userName = userName
    _this.userInfo.userHeadImg = userHeadImg
    _this.userInfo.userGroup = userGroup
    _this.userInfo.clientId = clientId
    _this.userInfo.clientType = clientType
    _this.userInfo.clientIp = clientIp
    _this.userInfo.clientIpRegion = clientIpRegion
    _this.userInfo.clientOs = clientOs
    _this.userInfo.clientBrowse = clientBrowse
    _this.userInfo.online = online
    _this.userInfo.sourceSite = sourceSite
    _this.userInfo.lastServiceAgentId = lastServiceAgentId
    _this.event.connectSuccess = connectSuccess
    _this.event.connectClose = connectClose
    _this.event.connectRetry = connectRetry
    _this.event.heartbeat = heartbeat
    _this.event.accessSuccess = accessSuccess
    _this.event.chatMsgReceived = chatMsgReceived
    _this.event.waitMsgReceived = waitMsgReceived
    _this.event.agentAccessMsgReceived = agentAccessMsgReceived
    _this.event.sessionOverMsgReceived = sessionOverMsgReceived
    _this.event.responseMsgReceived = responseMsgReceived
    _this.event.commentMsgReceived = commentMsgReceived
    _this.event.agentChangeMsgReceived = agentChangeMsgReceived
    _this.getWebsocket()
  },
  getWebsocket() {
    var _this = this
    if (!window.WebSocket) {
      window.WebSocket = window.MozWebSocket
    }
    if (window.WebSocket) {
      _this.socket = new WebSocket(_this.wsUrl)
      _this.socket.onmessage = function (event) {
        var msg = JSON.parse(event.data)
        if (msg.msgType == _this.msgType.heartMsg) {
          _this.handleHeartbeatMsg(msg)
        } else if (msg.msgType == _this.msgType.accessResultMsg) {
          _this.handleCustAccessMsg(msg)
        } else if (msg.msgType == _this.msgType.waitMsg) {
          _this.handleWaitMsg(msg)
        } else if (msg.msgType == _this.msgType.agentAccessMsg) {
          _this.handleAgentAccessMsg(msg)
        } else if (msg.msgType == _this.msgType.chatMsg) {
          _this.handleChatMsg(msg)
        } else if (msg.msgType == _this.msgType.sessionOverMsg) {
          _this.handleSessionOverMsg(msg)
        } else if (msg.msgType == _this.msgType.responseMsg) {
          _this.handResponseMsg(msg)
        } else if (msg.msgType == _this.msgType.commentMsg) {
          _this.handCommentMsg(msg)
        } else if (msg.msgType == _this.msgType.agentChangeMsg) {
          _this.handAgentChangeMsg(msg)
        }
      }
      _this.socket.onopen = function () {
        // 连接成功
        _this.status = 1
        _this.retryTimes = 0
        _this.event.connectSuccess()
        _this.sendCustAccessMsg()
      }
      _this.socket.onclose = function () {
        // 连接关闭
        if (_this.status == 4) {
          return
        }
        _this.status = 5
        clearInterval(_this.heartTimer)
        _this.retryConnect()
      }
    } else {
      alert('您的浏览器不支持WebSocket协议！')
    }
  },
  retryConnect() {
    let _this = this
    setTimeout(() => {
      _this.retryTimes++
      if (_this.retryTimes <= 10) {
        _this.event.connectRetry()
        _this.getWebsocket()
      } else {
        _this.event.connectClose()
      }
    }, 3000)
  },
  /**
   * 设置事件
   * @param eventType
   * @param func
   */
  setEvent(eventType, func) {
    this.event[eventType] = func
  },
  /**
   * 开启心跳
   */
  startHeart: function () {
    //开启心跳
    var _this = this
    _this.heartTimer = setInterval(function () {
      var dataContent = {
        userId: _this.userInfo.userId,
        userType: _this.userInfo.userType
      }
      _this.sendMsg(_this.msgType.heartMsg, dataContent)
    }, 50000)
  },
  /**
   * 发送用户接入成功消息
   */
  sendCustAccessMsg() {
    var msg = {
      custId: this.userInfo.userId,
      custName: this.userInfo.userName,
      custHeadImg: this.userInfo.userHeadImg,
      custGroup: this.userInfo.userGroup,
      clientId: this.userInfo.clientId,
      clientType: this.userInfo.clientType,
      clientIp: this.userInfo.clientIp,
      clientIpRegion: this.userInfo.clientIpRegion,
      clientOs: this.userInfo.clientOs,
      clientBrowse: this.userInfo.clientBrowse,
      online: this.userInfo.online,
      sourceSite: this.userInfo.sourceSite,
      lastServiceAgentId: this.userInfo.lastServiceAgentId
    }
    this.sendMsg(this.msgType.custAccessMsg, msg)
  },
  /**
   * 发送客服消息
   * @param toId
   * @param msgId
   * @param msgType
   * @param msgContent
   * @param url
   */
  sendChatMsg(toId, msgId, msgType, msgContent, url) {
    var msg = {
      sessionId: this.sessionInfo.sessionId,
      clientId: this.userInfo.clientId,
      msgId: msgId,
      fromId: this.userInfo.userId,
      toId: toId,
      toType: 2,
      msgContentType: msgType,
      content: msgContent,
      url: url
    }
    this.sendMsg(this.msgType.chatMsg, msg)
  },
  /**
   * 发送会话结束消息
   */
  sendSessionOverMsg() {
    var msg = {
      sessionId: this.sessionInfo.sessionId,
      senderType: 1
    }
    this.sendMsg(this.msgType.sessionOverMsg, msg)
  },
  /**
   * 发送评论消息
   * @param {*} agentId
   * @param {*} commentScore
   * @param {*} commentContent
   */
  sendCommentMsg(agentId, commentScore, commentContent) {
    var msg = {
      sessionId: this.sessionInfo.sessionId,
      custId: this.userInfo.userId,
      agentId: agentId,
      commentScore: commentScore,
      commentContent: commentContent
    }
    this.sendMsg(this.msgType.commentMsg, msg)
  },
  /**
   * 发送消息
   * @param msgType
   * @param msg
   */
  sendMsg: function (msgType, msg) {
    if (!window.WebSocket) {
      return
    }
    if (this.status == 4) {
      this.status = 0
      this.getWebsocket()
    } else if (this.socket.readyState == WebSocket.OPEN) {
      var commonFiled = this.getCommonFiled(msgType)
      var fullMsg = Object.assign(commonFiled, msg)
      this.socket.send(JSON.stringify(fullMsg))
    } else {
      alert('WebSocket 连接没有建立成功！')
    }
  },
  /**
   * 接收客户接入成功消息
   * @param msg
   */
  handleCustAccessMsg(msg) {
    if (msg.status == 0) {
      this.status = 2
      this.sessionInfo.sessionId = msg.sessionId
      this.startHeart()
      this.event.accessSuccess(msg)
    } else {
      console.error('客户接入失败')
    }
  },
  /**
   * 接收等待消息
   * @param msg
   */
  handleWaitMsg(msg) {
    this.event.waitMsgReceived(msg)
  },
  /**
   * 坐席分配成功消息
   * @param msg
   */
  handleAgentAccessMsg(msg) {
    this.status = 3
    this.sessionInfo.agentId = msg.agentId
    this.event.agentAccessMsgReceived(msg)
  },
  /**
   * 会话结束消息
   * @param msg
   */
  handleSessionOverMsg(msg) {
    this.status = 4
    clearInterval(this.heartTimer)
    this.event.sessionOverMsgReceived(msg)
  },
  /**
   * 心跳消息处理
   * @param msg
   */
  handleHeartbeatMsg(msg) {
    this.event.heartbeat(msg)
  },
  /**
   * 心跳消息处理
   * @param msg
   */
  handleChatMsg(msg) {
    this.event.chatMsgReceived(msg)
  },
  /**
   * 应答消息处理
   * @param {应答消息} msg
   */
  handResponseMsg(msg) {
    this.event.responseMsgReceived(msg)
  },
  /**
   * 评论消息处理
   * @param {评论消息} msg
   */
  handCommentMsg(msg) {
    this.event.commentMsgReceived(msg)
  },
  /**
   * 坐席转移消息处理
   * @param {坐席转移消息} msg
   */
  handAgentChangeMsg(msg) {
    this.sessionInfo.sessionId = msg.newSessionId
    this.sessionInfo.agentId = msg.newAgentId
    this.event.agentChangeMsgReceived(msg)
  }
}
