<template>
  <div
    class="div-editable"
    contenteditable="true"
    v-html="innerText"
    @input="changeText"
    @focus="isChange = false"
    @blur="blurFunc"
    :placeholder="placeholder"
    ref="inputRef"
  />
</template>

<script setup name="DivEditable">
import { defineProps, ref, watch, defineEmits, nextTick } from 'vue'
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: '请输入消息内容……'
  }
})
const emit = defineEmits(['update:modelValue'])
const innerText = ref(null)
const isChange = ref(true)
watch(
  () => props.modelValue,
  newVal => {
    if (isChange.value) {
      innerText.value = newVal
    }
    if (!props.modelValue) {
      nextTick(() => {
        inputRef.value.innerText = ''
      })
    }
  },
  { immediate: true }
)
const inputRef = ref(null)
const changeText = () => {
  emit('update:modelValue', inputRef.value.innerText.replace(/<div><br><\/div>/g, '<br>'))
}
const blurFunc = () => {
  isChange.value = true
}
</script>

<style lang="less">
.div-editable {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  word-break: break-all;
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  text-align: left;
  &[contenteditable='true'] {
    user-modify: read-write-plaintext-only;
    &:empty:before {
      content: attr(placeholder);
      font-size: 14px;
      display: block;
      color: #ccc;
    }
  }
}
</style>
