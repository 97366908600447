<template>
  <div class="chat-header" :style="{ background: themeColor }">
    <div class="title">
      <template v-if="nav.type == 1">{{ `${nav.text}${config.pageTitle}` }}</template>
      <template v-if="nav.type == 2">
        <el-image class="logo" :src="nav.logo" fit="contain" />
        {{ config.pageTitle }}
      </template>
    </div>
    <span class="btn" @click="commentOpen" v-if="props.showType ===2">
      <el-icon><Star /></el-icon>
      评价
    </span>
    <span class="btn" @click="changeService" v-if="props.showType === 1">
      <img src="@/assets/images/service.png" alt="" />
      转人工
    </span>
  </div>
</template>
<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { Star } from '@element-plus/icons-vue'
const props = defineProps({
  styleConfig: { type: Object, default: () => {} },
  showType: { type: Number, default: 1 }
})
const emit = defineEmits(['comment','switch'])
const config = computed(() => {
  if (props.styleConfig) {
    return props.styleConfig
  } else {
    return {
      themeColor: '#3571F0',
      pageTitle: '',
      navigatorContent: { text: '', type: 1 }
    }
  }
})
const nav = computed(() => {
  let defaultNav = { text: '', logo: '', type: 1 }
  if (config.value && config.value.navigatorContent) {
    return config.value.navigatorContent
  }
  return defaultNav
})
const themeColor = computed(() => {
  return config.value.themeColor
})
const commentOpen = () => {
  emit('comment')
}
// 转人工
const changeService=()=>{
  emit('switch')
}
</script>
<style scoped lang="less">
.chat-header {
  background: #3571f0;
  height: 44px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  .title {
    display: flex;
    align-items: center;
    .logo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
  .btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 3px 10px;
    border-radius: 4px;
    .el-icon{
      font-size: 18px;
      margin-right: 4px;
    }
    img{
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  }
}
</style>
