<template>
  <!--留言回复列表-->
  <div class="replay">
    <div v-if="chatMSgForm.msgStatus >= 2">
      <div class="replayData">
        <span>状态：{{ getStatusText(chatMSgForm.msgStatus) }}</span>
      </div>
      <div class="line"></div>
      <div v-for="o in dealMsgList" :key="o.index" class="replayDataItem">
        <img src="@/assets/images/h5/head.png" class="avatar" />
        <div class="rightClass">
          <div class="itemName">
            <div>平台</div>
            <div>{{ dateTimeFormatStr(o.dealTime) }}</div>
          </div>
          <div class="msg">{{ o.dealContent }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="ChatLeaveMsgReplayList">
import * as utils from '@/utils/index.js'
import { ElMessage } from 'element-plus'
import { defineProps, ref, watch, getCurrentInstance } from 'vue'
const props = defineProps({
  messageId: { type: String }
})
const dealMsgList = ref([])
const chatMSgForm = ref({ msgStatus: 0 })
const { proxy } = getCurrentInstance
const getDealMsgList = msgId => {
  proxy.$http
    .get('/cs/app/custClient/getDealLeaveMsg', { params: { msgId: msgId } })
    .then(({ data }) => {
      if (data.code == 0) {
        dealMsgList.value = data.data.list
        chatMSgForm.value = data.data.info
      } else {
        ElMessage.error(data.msg)
      }
    })
}
const getStatusText = status => {
  let s = {
    0: '未处理',
    1: '未处理',
    2: '处理中',
    3: '已处理'
  }
  return s[status]
}
const dateTimeFormatStr = val => {
  return utils.dateTimeFormat(val)
}
watch(
  () => props.messageId,
  newVal => {
    if (newVal) {
      //获取处理留言列表
      getDealMsgList(newVal)
    }
  },
  { immediate: true }
)
</script>
<style scoped>
.replay {
  font-size: 12px;

  .line {
    margin-top: 8px;
    width: 100%;
    border-bottom: 1px solid silver;
  }

  .replayData {
    margin-top: 5px;
  }
  .replayDataItem {
    margin-top: 8px;
    display: flex;
    .rightClass {
      display: flex;
      flex-direction: column;
      flex: 1;
      .itemName {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        color: #888888;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
      }
      .msg {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        line-height: 14px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .avatar {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    position: relative;
  }
}
</style>
