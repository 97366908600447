<template>
  <div class="chat-comment" v-if="vis" @click="close">
    <div class="comment-panel" @click.stop="e => {}">
      <div class="header">
        <div class="title">请对本次服务进行评价</div>
        <el-icon class="icon" @click="close"><Close /></el-icon>
      </div>
      <div class="body">
        <div class="input">
          <el-rate v-model="form.score" show-text :texts="scoreText" text-color="#ff9900" />
        </div>
        <div class="input">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.content"
            :maxlength="200"
            show-word-limit
            placeholder="请输入评价内容"
          />
        </div>
        <div class="action">
          <el-button class="btn" type="primary" :style="{ background: '#3571F0' }" @click="confirm">
            提交评价
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineEmits, defineExpose } from 'vue'
import { Close } from '@element-plus/icons-vue'
const emit = defineEmits(['confirm'])
const vis = ref(false)
const scoreText = ref(['非常不满意', '不满意', '满意', '比较满意', '非常满意'])
const form = reactive({})
const open = () => {
  vis.value = true
}
const close = () => {
  vis.value = false
  form.score = 5
  form.content = ''
}
const confirm = () => {
  emit('confirm', form)
  close()
}
defineExpose({
  open
})
</script>
<style scoped lang="less">
.chat-comment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .comment-panel {
    background-color: #ffffff;
    border-radius: 10px;
    width: 300px;
    height: 353px;
    .header {
      padding: 0 10px;
      line-height: 62px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      .title {
        color: #666666;
      }
      .icon {
        color: #999999;
        cursor: pointer;
      }
    }
    .body {
      padding: 30px 10px;
      .input {
        display: flex;
        font-size: 14px;
        color: #666666;
        margin-bottom: 30px;
      }
      .action {
        .btn {
          width: 100%;
          border: none;
        }
      }
    }
  }
}
</style>
