<template>
  <div class="ly-emotion">
    <slot></slot>
  </div>
</template>

<script setup name="ly-emotion">
import { onMounted, getCurrentInstance, nextTick } from 'vue'
import { emojiJSON } from '../../utils/emoji'
const { proxy } = getCurrentInstance()
onMounted(() => {
  let name = proxy.$el.innerHTML
  let list = emojiJSON
  let index = list.indexOf(name)
  let imgHTML = `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif"  align="top">`
  nextTick(() => {
    proxy.$el.innerHTML = imgHTML
  })
})
</script>
<style scoped>
.ly-emotion {
  display: inline-block;
}
.ly-static-emotion {
  width: 24px;
  height: 24px;
  display: inline-block;
}
</style>
