/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * Get the first item that pass the test
 * by second argument function
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find(list, f) {
  return list.filter(f)[0]
}

/**
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy(obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // if obj is hit, it is in circular structure
  const hit = find(cache, c => c.original === obj)
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy
  })

  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

/**
 * 将时间格式化为yyyy-MM-dd
 * @param value
 * @returns {*}
 */
export function dateFormat(value) {
  if (value == '') {
    return ''
  }
  if (!(value instanceof Date)) {
    value = new Date(value)
  }
  var time = value
  var y = time.getFullYear()
  var m = time.getMonth() + 1
  var d = time.getDate()
  return y + '-' + add0(m) + '-' + add0(d)
}
/**
 * 将时间格式化为yyyy-MM-dd HH:mm:ss
 * @param value
 * @returns {*}
 */
export function dateTimeFormat(value) {
  if (!value || value == '') {
    return ''
  }
  if (!(value instanceof Date)) {
    if (value instanceof String) {
      value = value.replace(/-/g, '/')
    }
    if (!isNaN(value)) {
      if ((value + '').length == 10) {
        value = value * 1000
      }
    }
    value = new Date(value)
  }
  var time = value
  var y = time.getFullYear()
  var m = time.getMonth() + 1
  var d = time.getDate()
  var h = time.getHours()
  var ms = time.getMinutes()
  var s = time.getSeconds()
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(ms) + ':' + add0(s)
}

/**
 * 将时间格式化为HH:mm
 * @param value
 * @returns {*}
 */
export function timeFormat(value, fmt = 'y-M-d H:m:s') {
  if (!value || value == '') {
    return ''
  }
  if (!(value instanceof Date)) {
    if (value instanceof String) {
      value = value.replace(/-/g, '/')
    }
    if (!isNaN(value)) {
      if ((value + '').length == 10) {
        value = value * 1000
      }
    }
    value = new Date(value)
  }
  var time = value
  var y = time.getFullYear()
  var m = time.getMonth() + 1
  var d = time.getDate()
  var h = time.getHours()
  var ms = time.getMinutes()
  var s = time.getSeconds()
  return fmt
    .replace('y', y)
    .replace('M', add0(m))
    .replace('d', add0(d))
    .replace('H', add0(h))
    .replace('m', add0(ms))
    .replace('s', add0(s))
}

/**
 * 左边加指定数目的0
 * @param m
 * @param num
 * @returns {string}
 */
export function add0(m, num) {
  if (!num) {
    num = 1
  }
  var maxNum = 10
  var max0 = '0'
  for (var i = 1; i < num; i++) {
    maxNum = maxNum * 10
    max0 = max0 + '0'
  }
  return m < maxNum ? max0 + m : m
}

/*
  在原有时间上增加秒
 */
export function timeAdd(value, add) {
  value = new Date(value)
  var t_s = value.getTime() //转化为时间戳毫秒数
  value.setTime(t_s + add * 1000)
  return value
}
/*
  在原有时间上减少秒
 */
export function timeDown(value, down) {
  value = new Date(value)
  var t_s = value.getTime() //转化为时间戳毫秒数
  value.setTime(t_s - down * 1000)
  return value
}
// 分类树
export function listClassTree(list) {
  let root = null
  if (list && list.length) {
    root = { sortId: 0, parentId: null, children: [] }
    const group = {}
    for (let index = 0; index < list.length; index += 1) {
      if (list[index].parentId !== null && list[index].parentId !== undefined) {
        if (!group[list[index].parentId]) {
          group[list[index].parentId] = []
        }
        group[list[index].parentId].push(list[index])
      }
    }
    const queue = []
    queue.push(root)
    while (queue.length) {
      const node = queue.shift()
      node.children = group[node.sortId] && group[node.sortId].length ? group[node.sortId] : null
      if (node.children) {
        queue.push(...node.children)
      }
    }
  }
  return root
}

/**
 * 获取客户端操作系统
 * @returns {string}
 */
export function getClientOs() {
  const userAgent = navigator.userAgent
  if (/android/i.test(navigator.userAgent)) return 'Android'
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) return 'iOS'
  if (/Linux/i.test(navigator.userAgent)) return 'Linux'
  if (/MicroMessenger/i.test(navigator.userAgent)) return 'Weixin'
  if (userAgent.indexOf('Windows NT 10.0') !== -1) return 'Windows 10'
  if (userAgent.indexOf('Windows NT 6.2') !== -1) return 'Windows 8'
  if (userAgent.indexOf('Windows NT 6.1') !== -1) return 'Windows 7'
  if (userAgent.indexOf('Windows NT 6.0') !== -1) return 'Windows Vista'
  if (userAgent.indexOf('Windows NT 5.1') !== -1) return 'Windows XP'
  if (userAgent.indexOf('Windows NT 5.0') !== -1) return 'Windows 2000'
  if (userAgent.indexOf('Mac') !== -1) return 'Mac/iOS'
  if (userAgent.indexOf('X11') !== -1) return 'UNIX'
  return 'Other'
}

/**
 * 获取来源站
 * @returns {string}
 */
export function getReferrer() {
  return document.referrer
}

/**
 * 获取浏览器类型
 * @returns {string}
 */
export function getClientBrowse() {
  function _mime(option, value) {
    var mimeTypes = navigator.mimeTypes
    for (var mt in mimeTypes) {
      if (mimeTypes[mt][option] == value) {
        return true
      }
    }
    return false
  }

  let ua = navigator.userAgent.toLocaleLowerCase()
  let browserType = null
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = 'IE'
  } else if (ua.match(/firefox/) != null) {
    browserType = 'firefox'
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = 'UC'
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = 'opera'
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = 'baidu'
  } else if (ua.match(/metasr/) != null) {
    browserType = 'sougou'
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = 'QQ'
  } else if (ua.match(/maxthon/) != null) {
    browserType = 'maxthon'
  } else if (ua.match(/chrome/) != null) {
    var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
    if (is360) {
      browserType = '360'
    } else {
      browserType = 'chrome'
    }
  } else if (ua.match(/safari/) != null) {
    browserType = 'Safari'
  } else {
    browserType = 'others'
  }
  return browserType
}

// 判断是否在微信小程序中
export function isWx() {
  let ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return new Promise(resolve => {
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          resolve('mini-wx')
        } else {
          resolve('wx')
        }
      })
    })
  } else {
    return new Promise(resolve => {
      resolve('no-wx')
    })
  }
}

// hex颜色转rgba
export function HtoRgba(bgcolor, alpha) {
  let color = bgcolor.slice(1)
  let rgba = [
    parseInt('0x' + color.slice(0, 2)),
    parseInt('0x' + color.slice(2, 4)),
    parseInt('0x' + color.slice(4, 6)),
    alpha
  ]
  return `rgba(${rgba.toString()})`
}

// 是否为json
export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str)
      if (typeof obj == 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}

// 深拷贝
export function deepClone(obj) {
  if (typeof obj == 'object') {
    const obj_ = Array.isArray(obj) ? [] : {}
    for (let i in obj) {
      obj_[i] = deepClone(obj[i])
    }
    return obj_
  } else {
    return obj
  }
}

export function compare(prop) {
  return function (a, b) {
    return a[prop] - b[prop]
  }
}

export function generateUUID() {
  let d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now()
  }
  let uuid = 'xxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
