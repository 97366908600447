import { createRouter, createWebHistory } from 'vue-router'
import service from '@/view/service.vue'

const routes = [
  {
    path: '/:orgId/:appId',
    name: 'service',
    component: service
  },
  {
    path: '/:orgId/:appId/:robotId',
    name: 'service1',
    component: service
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
