<template>
  <div class="container">
    <div class="chat-panel">
      <ChatHeader
        class="chat-header"
        :show-type="pageStatus"
        :styleConfig="styleConfig"
        @comment="openComment"
        @switch="changeSwitch"
      />
      <ChatMsg
        ref="chatMsgRef"
        class="chat-msg"
        :styleConfig="styleConfig"
        :chatMsg="chatMsg"
        :orgId="appInfo.orgId"
        :btnLoading="robotInfo.btnLoading"
        @loadHistory="getChatMsgHistory"
        @sendMsg="sendMsg"
      />
      <ChatInput
        ref="chatInputRef"
        class="chat-input"
        :styleConfig="styleConfig"
        :sessionStatus="sessionStatus"
        :orgId="appInfo.orgId"
        :show-type="pageStatus"
        @sendMsg="sendMsg"
        @comment="openComment"
        @quit="closeSession"
        @reconnnect="openSession"
        @leaveMsg="openLeaveMsg"
      />
      <ChatComment ref="chatCommentRef" @confirm="sendCommentMsg" />
      <ChatLeaveMsg
        ref="chatLeaveMsgRef"
        :sessionStatus="sessionStatus"
        :custInfo="custInfo"
        @confirm="confrimLeaveMsg"
        :orgId="appInfo.orgId"
      />
    </div>
  </div>
</template>
<script setup>
import * as utils from '../utils/index.js'
import ChatHeader from './common/ChatHeader.vue'
import ChatMsg from './common/ChatMsg.vue'
import ChatInput from './common/ChatInput.vue'
import ChatComment from './common/ChatComment.vue'
import ChatLeaveMsg from './common/ChatLeaveMsg.vue'
import { ref, getCurrentInstance, reactive, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
const route = useRoute()
const { proxy } = getCurrentInstance()
const appInfo = reactive({ url: proxy.$config.wsURL })
const clientInfo = ref({})
const custInfo = ref({})
const localCustInfo = reactive({})
const chatMsg = reactive({
  openTime: new Date().getTime(),
  loading: 0,
  pageNo: 0,
  pageSize: 20,
  list: []
})
const styleConfig = ref({})
const sessionStatus = ref(0)
const defaultAvatar = ref(
  'https://cys86-test.oss-cn-hangzhou.aliyuncs.com/vote/20200414/4edd69efad254107975ed08064c2517f.jpg'
)
const defaultNickname = ref(`新客户${Math.round(Math.random() * 899 + 100)}`)
// 页面状态
const pageStatus = ref(1) //接入机器人 1 接入客服员 2
// 初始化机器人
const robotInfo = reactive({
  btnLoading: false
})
const initRobotInfo = () => {
  let params = route.params
  if (!params.orgId || !params.appId) {
    return ElMessage.error('参数错误')
  }
  appInfo.appId = params.appId
  appInfo.orgId = params.orgId
  if (params.robotId) {
    robotInfo.id = params.robotId
    pageStatus.value = 1
  } else {
    pageStatus.value = 2
  }
  // 获取机器人客户信息
  let id = localStorage.getItem('senderId')
  if (id) {
    robotInfo.senderId = id
  } else {
    robotInfo.senderId = utils.generateUUID()
    localStorage.setItem('senderId', robotInfo.senderId)
  }
  initLocalCust()
  getServerInfo()
  getPageStyle()
}
// 获取机器人基本配置
const getStyleConfig = () => {
  if (!robotInfo.id) {
    console.error('未获取到机器人编号')
    return
  }
  proxy.$http.get(`/textbot-server/app/client/bot/${robotInfo.id}`).then(({ data }) => {
    if (data.code === 0 && data.data) {
      robotInfo.welcomeMsg = data.data?.welcomeMsg || '你好！'
      if (pageStatus.value === 1) {
        chatMsg.list.push({
          value: 2, //客服信息
          type: 1,
          text: robotInfo.welcomeMsg,
          time: new Date().getTime(),
          headePortrait: ''
        })
        if (data.data?.quickFunctionConfig && data.data?.quickFunctionConfig?.quickFunctionList?.length > 0) {
          let msg = {
            value: 2,
            type: 7,
            time: new Date().getTime(),
            headePortrait: '',
            quick_replies: data.data?.quickFunctionConfig.quickFunctionList.map((i) => i.msg) || []
          }
          chatMsg.list.push(msg)
        }
      }
    }
  })
  console.log(chatMsg.list, '消息')
}
const initLocalCust = () => {
  let query = route.query
  if (query && query.openid) {
    localCustInfo.openid = query.openid
    localCustInfo.nickname = query.nickname || defaultNickname.value
    localCustInfo.avatar = query.avatar || defaultAvatar.value
  } else {
    let cacheCustInfo = localStorage.getItem(`cacheCustInfo_${appInfo.orgId}_${appInfo.appId}`)
    if (cacheCustInfo && cacheCustInfo != '{}') {
      cacheCustInfo = JSON.parse(cacheCustInfo)
      localCustInfo.openid = cacheCustInfo.openid
      localCustInfo.nickname = cacheCustInfo.nickname
      localCustInfo.avatar = cacheCustInfo.avatar
    } else {
      localCustInfo.openid = `local_${new Date().getTime()}`
      localCustInfo.nickname = defaultNickname.value
      localCustInfo.avatar = defaultAvatar.value
    }
  }
  localStorage.setItem(`cacheCustInfo_${appInfo.orgId}_${appInfo.appId}`, JSON.stringify(localCustInfo))
}
const getServerInfo = () => {
  let data = {
    orgId: appInfo.orgId,
    appId: appInfo.appId,
    openid: localCustInfo.openid,
    mobile: localCustInfo.mobile,
    nickname: localCustInfo.nickname,
    avatar: localCustInfo.avatar,
    clientType: clientInfo.value.clientType
  }
  proxy.$http
    .post('/cs/app/custClient/client/init', data)
    .then(({ data }) => {
      if (data.code == 0) {
        // 应用信息
        let info = data.data.appInfo
        appInfo.appName = info.appName
        appInfo.appImg = info.appImg
        appInfo.appDesc = info.appDesc
        // 客户信息
        custInfo.value = data.data.custInfo
        // 客户端信息
        clientInfo.value = data.data.clientInfo
        if (pageStatus.value === 2) {
          // 建立连接
          initWS()
          // 获取历史聊天记录
          getChatMsgHistory().finally(() => {
            scrollToBottom()
          })
        } else {
          // 获取机器人配置
          getStyleConfig()
        }
      } else {
        ElMessage.error(data.msg)
      }
    })
    .catch(() => {
      ElMessage.error('网络连接异常，请稍候再试')
    })
}
const initWS = () => {
  proxy.$ws.init({
    url: appInfo.url,
    appId: appInfo.appId,
    appName: appInfo.appName,
    orgId: appInfo.orgId,
    userId: custInfo.value.custId,
    userName: custInfo.value.custName,
    userHeadImg: custInfo.value.custAvatar,
    userGroup: custInfo.value.custGroup,
    lastServiceAgentId: custInfo.value.lastServiceAgentId,
    clientId: clientInfo.value.clientId,
    clientType: clientInfo.value.clientType,
    clientIp: clientInfo.value.clientIp,
    clientIpRegion: clientInfo.value.clientIpRegion,
    clientOs: clientInfo.value.clientOs,
    clientBrowse: clientInfo.value.clientBrowse,
    online: custInfo.value.online,
    sourceSite: utils.getReferrer(),
    connectSuccess: (data) => {
      console.log('ws连接成功', data)
    },
    connectClose: (data) => {
      wsConnectClose(data)
    },
    accessSuccess: (msg) => {
      // 客户连接成功
      custAccessSuccessHandle(msg)
    },
    agentAccessMsgReceived: (msg) => {
      // 坐席分配成功
      agentAccessSuccessHandle(msg)
    },
    chatMsgReceived: (msg) => {
      // 接收聊天消息
      chatMsgReceivedHandle(msg)
    },
    responseMsgReceived: (msg) => {
      // 接受应答消息
      responseMsgReceivedHandle(msg)
    },
    sessionOverMsgReceived: (msg) => {
      sessionOverMsgReceivedHandle(msg)
    },
    agentChangeMsgReceived: (msg) => {
      agentChangeMsgReceivedHandle(msg)
    },
    waitMsgReceived: (msg) => {
      waitMsgReceivedHandle(msg)
    }
  })
}
const wsConnectClose = () => {
  chatMsg.list.push({
    value: 4,
    text: "与服务端连接断开，请检查网络设置后<a style='color:blue'>点击此处</a>重试"
  })
  sessionStatus.value = 0
}
const custAccessSuccessHandle = (msg) => {
  // 客户连接成功
  sessionStatus.value = 1
  chatMsg.list.push({
    value: 1, //系统信息
    text: msg.msg || '连接成功，等待系统分配客服'
  })
  scrollToBottom()
}
const agentInfo = ref({})
const agentAccessSuccessHandle = (msg) => {
  // 坐席分配成
  sessionStatus.value = 2
  agentInfo.value = {
    agentId: msg.agentId,
    agentName: msg.agentName,
    agentHeadImg: msg.agentHeadImg
  }
  chatMsg.list.push({
    value: 2, //客服信息
    type: 1,
    text: msg.greeting,
    time: msg.timestamp,
    headePortrait: msg.agentHeadImg
  })
  scrollToBottom()
}
const chatMsgReceivedHandle = (msg) => {
  // toType:2客户 1客服
  let data = {
    value: msg.toType == 2 ? 3 : 2,
    text: msg.content,
    type: msg.msgContentType,
    obj: utils.isJSON(msg.content) ? JSON.parse(msg.content) : {},
    time: msg.timestamp,
    headePortrait: msg.toType == 2 ? custInfo.value.custAvatar : agentInfo.value.agentHeadImg
  }
  chatMsg.list.push(data)
  scrollToBottom() // 滚动到底部
}
const responseMsgReceivedHandle = (msg) => {
  if (msg.responseMsgType == proxy.$ws.msgType.commentMsg) {
    // 评价消息
    if (msg.expose) {
      chatMsg.list.push({
        value: 1, //系统信息
        text: msg.msg,
        type: 1
      })
    }
  } else if (msg.responseMsgType == proxy.$ws.msgType.notInServiceTimeMsg) {
    // 不在服务时间
    sessionStatus.value = 3
    if (msg.expose) {
      chatMsg.list.push({
        value: 1, //系统信息
        text: msg.msg,
        type: 1
      })
    }
    scrollToBottom()
  }
}
const sessionOverMsgReceivedHandle = (msg) => {
  console.log('会话结束=====》')
  sessionStatus.value = 0
  // 会话结束
  chatMsg.list.push({
    value: 0, //历史消息
    type: 1,
    text: msg.reasonMsg
  })
  scrollToBottom() // 滚动到底部
  // 切换机器人
  pageStatus.value = 1
  closeSession()
}
const agentChangeMsgReceivedHandle = (msg) => {
  agentInfo.value = {
    agentId: msg.newAgentId,
    agentName: msg.newAgentName,
    agentHeadImg: msg.newAgentHeadImg
  }
  chatMsg.list.push({
    value: 1, //客服信息
    type: 1,
    text: msg.greeting,
    time: msg.timestamp,
    headePortrait: msg.agentHeadImg
  })
  scrollToBottom() // 滚动到底部
}
const waitMsgReceivedHandle = (msg) => {
  chatMsg.list.push({
    value: 1, //系统信息
    text: msg.msg || '您好，当前正在排队，请简单描述您的问题',
    type: 1
  })
  scrollToBottom() // 滚动到底部
}
const getPageStyle = () => {
  proxy.$http.get('/cs/app/h5ReadStyle', { params: { orgId: appInfo.orgId } }).then(({ data }) => {
    if (data.code == 0 && data.data) {
      if (_isMobile()) {
        utils.isWx().then((type) => {
          if (type == 'no-wx') {
            // H5端
            styleConfig.value = JSON.parse(data.data.h5Config)
          } else {
            // 小程序端
            styleConfig.value = JSON.parse(data.data.appConfig)
          }
        })
      } else {
        styleConfig.value = JSON.parse(data.data.webConfig)
      }
    }
  })
}
const getChatMsgHistory = (callback) => {
  return new Promise((resolve) => {
    if (chatMsg.loading != 0) {
      return
    }
    chatMsg.loading = 1
    chatMsg.pageNo++
    let params = {
      orgId: appInfo.orgId,
      appId: appInfo.appId,
      custId: custInfo.value.custId,
      lastMsgDate: chatMsg.openTime,
      pageNo: chatMsg.pageNo,
      pageSize: chatMsg.pageSize
    }
    proxy.$http
      .get('/cs/app/custClient/chatmsg/history', { params })
      .then(({ data }) => {
        if (data.code == 0) {
          let page = data.data
          let list = page.records
          let index = list.length
          if (page.current == Math.ceil(page.total / chatMsg.pageSize)) {
            chatMsg.loading = 2
          } else {
            chatMsg.loading = 0
          }
          list.forEach((item) => {
            chatMsg.list.push({
              value: item.toType + 1,
              type: item.msgContentType,
              text: item.content,
              time: item.timestamp,
              headePortrait: item.fromHeadImg,
              obj: utils.isJSON(item.content) ? JSON.parse(item.content) : {}
            })
          })
          nextTick(() => {
            if (callback) {
              callback(index)
            }
          })
          resolve(index)
        } else {
          ElMessage.error(data.msg)
        }
      })
      .catch(() => {
        chatMsg.loading = 0
        ElMessage.error('网络连接异常，请稍候再试')
      })
  })
}
// 手机端和pc端
const _isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}
// 开启会话
const openSession = () => {
  chatMsg.list.push({
    value: 1, //系统信息
    text: '系统将为您重新分配客服，请稍候',
    type: 1
  })
  proxy.$ws.sendCustAccessMsg()
}
// 结束会话
const closeSession = () => {
  proxy.$ws.sendSessionOverMsg()
}
// 滚动到底部
const chatMsgRef = ref(null)
const scrollToBottom = () => {
  chatMsgRef.value.scrollToBottom()
}
//发送消息
const isSend = ref(false)
const sendMsg = (msg) => {
  if (pageStatus.value === 2) {
    if ([1, 5].includes(proxy.$ws.status)) {
      ElMessage.error('网络未连接成功，请检查您的网络设置')
    } else if (proxy.$ws.status == 2) {
      ElMessage.error('您还未分配坐席，请稍候')
    } else if (proxy.$ws.status == 4) {
      // 开启会话
      openSession()
    } else {
      let { type, content, url } = msg
      proxy.$ws.sendChatMsg(agentInfo.value.agentId, new Date().getTime(), type, content, url)
      // 滚动条最底
      scrollToBottom()
    }
  } else {
    let reg = /转人工/
    if (reg.test(msg.content)) {
      changeSwitch()
      return
    }
    sendRobotMsg(msg.content)
  }
}
// 转人工
const changeSwitch = () => {
  pageStatus.value = 2
  initWS()
}
// 机器人发送消息
const sendRobotMsg = (msg) => {
  let data = {
    botId: robotInfo.id,
    sender: robotInfo.senderId,
    message: msg
  }
  if (!isSend.value) {
    robotInfo.btnLoading = true
    chatMsg.list.push({
      value: 3, //客户消息
      type: 1,
      text: msg,
      time: new Date().getTime(),
      headePortrait: custInfo.value.custAvatar || ''
    })
    scrollToBottom()
    proxy.$http.post('/textbot-server/app/client/send/msg', data).then(({ data }) => {
      isSend.value = true
      robotInfo.btnLoading = false
      if (data.code === 0) {
        data.data.botMsgList.forEach((item) => {
          let obj = {
            value: 2,
            time: new Date().getTime(),
            headePortrait: ''
          }
          if (item.text) {
            obj.text = item.text
            obj.type = 1
          }
          if (item.image) {
            obj.text = item.image
            obj.type = 3
          }
          chatMsg.list.push(obj)
          obj = null
        })
        if (data.data.intentList && data.data.intentList.length > 0) {
          let obj = { value: 2, type: 7, time: new Date().getTime(), headePortrait: '', quick_replies: [] }
          data.data.intentList.forEach((item) => {
            if (item.examples && item.examples.length > 0) {
              obj.quick_replies.push(item.examples[0])
            }
          })
          if (obj.quick_replies.length > 0) {
            chatMsg.list.push(obj)
          }
          obj = null
        }
        // 滚动条最底
        scrollToBottom()
      }
      isSend.value = false
    })
  }
}
// 发送评价消息
const sendCommentMsg = (msg) => {
  let { score, content } = msg
  proxy.$ws.sendCommentMsg(agentInfo.value.agentId, score, content)
}
// 评价
const chatCommentRef = ref(null)
const openComment = () => {
  chatCommentRef.value.open()
}
// 留言
const chatLeaveMsgRef = ref(null)
const openLeaveMsg = () => {
  chatLeaveMsgRef.value.open()
}
// 提交留言
const confrimLeaveMsg = (arg) => {
  let msg = {
    realName: arg.name,
    mobile: arg.mobile,
    msgContent: arg.message
  }
  chatMsg.list.push({
    value: 3, //回显留言
    text: JSON.stringify(msg),
    type: 11,
    obj: msg,
    time: new Date().getTime(),
    headePortrait: custInfo.value.custAvatar
  })
  scrollToBottom()
}
initRobotInfo()
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .chat-panel {
    position: relative;
    background: #fff;
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 960px) {
    .chat-panel {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
