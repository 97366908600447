<!--
value
1	心跳检测
2	客户接入
3	排队等待提醒
4	客户分配坐席成功
5	发送聊天消息
6	聊天消息消息应答
7	坐席状态变化
8	坐席转接
9	会话结束
10	客户或坐席信道建立应答
11	服务评价
-->
<!--
type
1	文本	包含纯文本和富文本
2	语音
3	图片
4	文件
5	链接
6	视频
7 机器人问题列表
11  留言
-->
<template>
  <div id="gundong" class="chat-msg">
    <div class="more">
      <span v-if="chatMsg.loading == 0">
        <i class="el-icon-bottom" />
        下拉查看更多
      </span>
      <span v-if="chatMsg.loading == 1">
        <i class="el-icon-loading" />
        数据加载中
      </span>
      <span v-if="chatMsg.loading == 2">没有更多记录啦</span>
    </div>
    <div class="list" v-for="(item, index) in msgList" :key="index" :id="`msg_${index}`">
      <!-- 时间 -->
      <div class="time">{{ timeHandle(index) }}</div>
      <!-- 0历史消息,1系统消息,4异常消息 -->
      <div class="sys" v-if="[0, 1, 4].includes(item.value)">
        <div v-html="item.text"></div>
      </div>
      <!-- 聊天消息 2客服，3客户-->
      <div v-else class="chat" :class="{ left: item.value == 2, right: item.value == 3 }">
        <img v-if="item.headePortrait != ''" :src="item.headePortrait" class="headImg" />
        <div v-else>
          <i v-if="item.value == 2" class="headerIcon iconfont icon-jiqiren"></i>
          <i v-if="item.value == 3" class="headerIcon iconfont icon-yonghu"></i>
        </div>
        <!-- 文本和图片 -->
        <div class="info" v-if="[1, 3].includes(item.type)">
          <div class="arrow" :style="item.value == 3 ? borderStyle : {}"></div>
          <div class="infoCon" :style="item.value == 3 ? backgroundStyle : {}">
            <div v-if="item.type == 1" v-html="item.text"></div>
            <el-image v-if="item.type == 3" :src="item.obj.url" :preview-src-list="[item.obj.url]" />
          </div>
        </div>
        <!-- 文件 -->
        <div class="info" v-if="item.type == 4">
          <div class="arrow" :style="item.value == 3 ? borderStyle : {}"></div>
          <div class="infoCon fileCon" :style="item.value == 3 ? backgroundStyle : {}">
            <i class="el-icon-document"></i>
            <div class="filedet">
              <div class="name">{{ item.obj && item.obj.name }}</div>
              <div class="wrap">
                <span class="size">{{ item.obj && sizeFilter(item.obj.size) }}</span>
                <i class="icon el-icon-download" @click="downloadFile(item)"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- 机器人问题列表 -->
        <div class="info question-list" v-if="item.type == 7">
          <div class="arrow" :style="item.value == 3 ? borderStyle : {}"></div>
          <div class="infoCon">
            <div class="title">猜你想问：</div>
            <div @click="handleSendMsg(i)" class="item" v-for="(i, index) in item.quick_replies" :key="index">
              {{ i }}
            </div>
          </div>
        </div>

        <!-- 留言 -->
        <div class="info" v-if="item.type == 11">
          <div class="arrow" :style="item.value == 3 ? borderStyle : {}"></div>
          <div class="infoCon" :style="item.value == 3 ? backgroundStyle : {}">
            <div class="card-msg">
              <div class="card-title">留言信息</div>
              <div class="card-body">
                <div class="card-data">姓名：{{ item.obj.realName }}</div>
                <div class="card-data">电话：{{ item.obj.mobile }}</div>
                <div class="card-data">留言内容：{{ item.obj.msgContent }}</div>
              </div>
              <div>
                <chat-leave-msg-replay-list :messageId="item.obj.msgId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 机器人消息加占位符 -->
    <div class="chat" v-if="props.btnLoading">
      <i class="headerIcon iconfont icon-jiqiren"></i>
      <div class="info">
        <div class="arrow"></div>
        <div class="infoCon">
          <bot-loading></bot-loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import * as utils from '@/utils/index.js'
import ChatLeaveMsgReplayList from './ChatLeaveMsgReplayList'
import BotLoading from '@/components/BotLoading'
import {
  defineProps,
  computed,
  onMounted,
  watch,
  defineEmits,
  getCurrentInstance,
  ref,
  nextTick,
  defineExpose
} from 'vue'
import { ElMessage } from 'element-plus'
const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => {}
  },
  chatMsg: {
    type: Object,
    default: () => {}
  },
  orgId: {
    type: String
  },
  btnLoading: {
    type: Boolean,
    default: false
  }
})
const msgList = computed(() => {
  let list = utils.deepClone(props.chatMsg?.list)
  return list.sort(utils.compare('time'))
})
const sizeFilter = (val) => {
  if (!val) {
    return '未知'
  } else if (val > 1024 * 1024) {
    return (val / 1024 / 1024).toFixed(2) + 'M'
  } else if (val > 1024) {
    return (val / 1024).toFixed(2) + 'KB'
  } else {
    return val + 'B'
  }
}
// 敏感词
const SenList = ref([])
const config = computed(() => {
  if (props.styleConfig) {
    return props.styleConfig
  } else {
    return {
      themeColor: '#3571F0',
      placeholder: '请输入内容...',
      pageTitle: '',
      navigatorContent: { text: '' }
    }
  }
})
const borderStyle = computed(() => {
  return {
    'border-left-color': utils.HtoRgba(config.value.themeColor, 0.1)
  }
})
const backgroundStyle = computed(() => {
  return {
    'background-color': utils.HtoRgba(config.value.themeColor, 0.1)
  }
})
onMounted(() => {
  nextTick(() => {
    document.getElementById('gundong').addEventListener('scroll', (e) => {
      let position = e.target.scrollTop
      let height = e.target.scrollHeight - e.target.clientHeight
      if (position == 0) {
        pullDown()
      }
      if (height - position == 0) {
        pullUp()
      }
    })
  })
})
// 时间格式化
const timeHandle = (index) => {
  let time = props.chatMsg.list[index].time
  if (index != 0) {
    let previewTime = props.chatMsg.list[index - 1].time
    if (time - previewTime < 10 * 60 * 1000) {
      time = 0
    }
  }
  if (time == 0) {
    return ''
  } else {
    let now = new Date()
    let timeObj = new Date(time)
    if (
      now.getFullYear() == timeObj.getFullYear() &&
      now.getMonth() == timeObj.getMonth() &&
      now.getDate() == timeObj.getDate()
    ) {
      return utils.timeFormat(time, 'H:m')
    } else {
      return utils.timeFormat(time, 'M月d日 H:m')
    }
  }
}
const emit = defineEmits(['loadHistory', 'sendMsg'])
// 下拉
const pullDown = () => {
  emit('loadHistory', (index) => {
    let postion = document.querySelector('#msg_' + index).offsetTop
    let con = document.getElementById('gundong')
    con.scrollTop = postion - 15
  })
}
// 上拉
const pullUp = () => {}
// 滚动到底部
const scrollToBottom = () => {
  nextTick(() => {
    let con = document.getElementById('gundong') // 获取对象
    setTimeout(() => {
      con.scrollTop = con.scrollHeight // 滚动高度
    }, 0)
  })
}
// 下载文件
const downloadFile = (data) => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  a.target = '_blank'
  a.href = data.obj.url
  a.download = data.obj.name
  a.click()
  document.body.removeChild()
}
// 快捷点击
const handleSendMsg = (content) => {
  emit('sendMsg', { content })
}
const { proxy } = getCurrentInstance()
watch(
  () => props.orgId,
  (newVal) => {
    if (newVal) {
      proxy.$http.get('/cs/app/ssensitiveword/listAll', { params: { orgId: newVal } }).then(({ data }) => {
        if (data.code == 0) {
          SenList.value = data.data.data.map((i) => i.wordContent)
        } else {
          ElMessage.error(data.msg)
        }
      })
    }
  },
  { immediate: true }
)
watch(
  () => props.chatMsg,
  (newVal) => {
    if (newVal && newVal.list && SenList.value.length > 0) {
      let list = utils.deepClone(newVal.list)
      if (list.length != 0 && SenList.value.length > 0) {
        list.forEach((item) => {
          if (item.value == 3 && item.type == 1 && item.text) {
            SenList.value.forEach((i) => {
              if (item.text.indexOf(i) != -1) {
                let reg = new RegExp(i, 'g')
                let rw = new Array(i.length).fill('*').join('')
                let res = item.text.replace(reg, rw)
                item.text = res
              }
            })
          }
        })
      }
    }
  },
  { immediate: true, deep: true }
)
defineExpose({
  scrollToBottom
})
</script>
<style scoped lang="less">
.chat-msg {
  background: #f0f1f2;
  flex: 1;
  overflow: auto;
  padding: 15px 0;

  .more {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #999;
    position: relative;

    span {
      padding: 0 5px;
      position: relative;
    }
  }

  .list {
    .time {
      text-align: center;
      font-size: 12px;
      color: #999;
      line-height: 40px;
    }

    .sys {
      text-align: center;
      color: #aaa;
      font-size: 13px;

      div {
        margin-top: 15px;
      }
    }
  }
  .chat {
    margin-top: 10px;
    text-align: left;
    padding: 0 20px;
    display: flex;

    .headImg {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 3px 15px 0 0;
      vertical-align: top;
    }
    .headerIcon {
      width: 30px;
      height: 30px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3px 15px 0 0;
    }
    .question-list {
      font-size: 15px;
      line-height: 2;
      .item {
        color: #227fff;
        cursor: pointer;
      }
    }

    .info {
      position: relative;
      color: #333;

      .arrow {
        position: absolute;
        top: 15px;
        width: 0;
        height: 0;
        border: 0 solid #ffffff;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        left: -6px;
        border-right-width: 6px;

        &::before {
          content: '';
          position: absolute;
          top: -5px;
          border: 0 solid #ffffff;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          z-index: 1;
        }
      }

      img {
        width: 80px;
        height: 80px;
      }

      .infoCon {
        background-color: #ffffff;
        padding: 10px 15px;
        border-radius: 5px;
        margin-bottom: 5px;
        max-width: 300px;
        word-break: break-all;
        min-height: 41px;
        box-sizing: border-box;

        &.fileCon {
          display: flex;
          font-size: 12px;
          text-align: left;

          i {
            font-size: 48px;
            margin-right: 5px;
            color: #666666;
          }

          .filedet {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              width: 130px;
            }

            .wrap {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;

              .size {
                color: #999;
              }

              .icon {
                color: #3571f0;
                font-size: 13px;
              }
            }
          }
        }
      }

      .infoTime {
        color: #999;
        font-size: 13px;
      }
    }

    .card-msg {
      .card-title {
        font-size: 12px;
        font-weight: bold;
      }

      .card-body {
        font-size: 12px;

        .card-data {
          margin-top: 5px;
        }
      }
    }

    &.right {
      flex-direction: row-reverse;

      .headImg {
        margin: 3px 0 0 15px;
      }

      .info {
        .infoCon {
          background: #dcedfa;
        }

        .arrow {
          position: absolute;
          top: 15px;
          width: 0;
          height: 0;
          border: 0 solid #dcedfa;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          right: -6px;
          left: auto;
          border-left-width: 6px;
        }
      }
    }
  }
}
</style>
