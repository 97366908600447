<template>
  <div class="chat-comment" v-if="vis" @click="close">
    <div class="comment-panel" @click.stop="e => {}">
      <div class="header">
        <div class="title">给我留言</div>
        <i class="icon el-icon-close" @click="close"></i>
      </div>
      <div class="body">
        <el-alert
          class="alert"
          title="当前客服不在线，如需帮助请留言"
          type="warning"
          show-icon
          :closable="false"
        />
        <el-form :model="dataForm" :rules="rules" ref="dataFormRef">
          <el-form-item label="姓名" prop="name">
            <el-input type="text" v-model="dataForm.name" placeholder="姓名" />
          </el-form-item>
          <el-form-item label="手机" prop="mobile">
            <el-input v-model="dataForm.mobile" placeholder="请输入手机" />
          </el-form-item>
          <el-form-item label="留言" prop="message">
            <el-input
              v-model="dataForm.message"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 3, maxRows: 3 }"
              placeholder="请输入留言"
            />
          </el-form-item>
          <el-form-item label-width="0">
            <el-button class="btn" type="primary" @click="confirm" :loading="loading">
              发送
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ElMessage } from 'element-plus'
import {
  defineProps,
  getCurrentInstance,
  ref,
  reactive,
  watch,
  defineEmits,
  defineExpose
} from 'vue'
const props = defineProps({
  sessionStatus: {
    type: [String, Number],
    default: 0
  },
  custInfo: {
    type: Object,
    default: () => {}
  },
  orgId: {
    type: [String, Number],
    default: ''
  }
})
const { proxy } = getCurrentInstance()
const vis = ref(false)
const loading = ref(false)
const dataForm = reactive({})
const rules = reactive({
  mobile: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误', trigger: 'blur' }
  ],
  name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  message: [{ required: true, message: '请输入留言内容', trigger: 'blur' }]
})
watch(
  () => props.orgId,
  newVal => {
    dataForm.orgId = newVal
  },
  { immediate: true }
)
const open = () => {
  vis.value = true
}
const close = () => {
  vis.value = false
  dataForm.name = ''
  dataForm.mobile = ''
  dataForm.message = ''
}
const dataFormRef = ref(null)
const emit = defineEmits(['confirm'])
const confirm = () => {
  dataFormRef.value.validate(valid => {
    if (valid) {
      loading.value = true
      let data = {
        custId: props.custInfo.custId,
        msgContent: dataForm.message,
        workingTimeFlag: props.sessionStatus == 3 ? 1 : 0,
        realName: dataForm.name,
        mobile: dataForm.mobile,
        orgId: dataForm.orgId
      }
      proxy.$http.post('cs/app/saveLeaveMsg', data).then(({ data }) => {
        if (data.code == 0) {
          emit('confirm', dataForm)
          close()
        } else {
          ElMessage.error(data.msg)
        }
      })
    }
  })
}
defineExpose({
  open
})
</script>
<style scoped lang="less">
.chat-comment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .comment-panel {
    background-color: #ffffff;
    border-radius: 4px;
    width: 90%;
    max-width: 400px;

    .header {
      padding: 0 10px;
      line-height: 62px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;

      .title {
        font-weight: bold;
      }

      .icon {
        color: #999999;
        cursor: pointer;
      }
    }

    .body {
      padding: 10px;

      .alert {
        margin-bottom: 10px;
      }

      .el-form-item {
        position: relative;

        /deep/ .el-form-item__label {
          position: absolute;
          left: 10px;
          top: 0;
          z-index: 1;
        }

        /deep/ .el-input__inner {
          padding-left: 60px;
        }
      }
    }

    .btn {
      width: 100%;
      border: none;
    }
  }
}
</style>
