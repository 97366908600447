import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { WS } from './utils/cust_websocket'
import axios from 'axios'
import './assets/icon/iconfont.css'

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
let config = {}
if (process.env.NODE_ENV == 'production') {
  config = {
    baseURL: 'https://pds-api.51yunzhimei.com',
    wsURL: 'wss://pds-api.51yunzhimei.com/csWs/ws'
  }
} else {
  config = {
    // baseURL: 'http://192.168.0.243:19004',
    // wsURL: 'ws://192.168.0.243:19004/csWs/ws'
    baseURL: 'https://pds-api.51yunzhimei.com',
    wsURL: 'wss://pds-api.51yunzhimei.com/csWs/ws'
  }
}
app.config.globalProperties.$config = config

let ws = new WS()
app.config.globalProperties.$ws = ws

let $http = axios.create({
  baseURL: config.baseURL,
  timeout: 60000,
  headers: { 'X-Custom-Header': 'wtcs-cust-client' }
})
app.config.globalProperties.$http = $http
app.mount('#app')
