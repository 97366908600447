<template>
  <div class="chat-input">
    <!-- PC端输入 -->
    <div class="chat-pc-input-panel">
      <div class="chat-input-tool" v-if="props.showType === 2">
        <img class="tool-item" src="@/assets/images/web/emoji.png" alt="表情" @click="emojiVis = !emojiVis" />
        <img class="tool-item" src="@/assets/images/web/pic.png" alt="图片" @click="fileUpload(3)" />
        <img class="tool-item" src="@/assets/images/web/file.png" alt="文件" @click="fileUpload(4)" />
        <img
          class="tool-item"
          v-for="(item, index) in shortcuts"
          :key="index"
          :src="item.icon"
          :alt="item.name"
          @click="custFunc(item)"
        />
      </div>
      <div class="chat-input-editor" @click="emojiVis = false">
        <DivEditable class="edior" v-model="msg" :placeholder="placeholder" />
        <button class="send-btn" @click="sendMsg(1)" :style="{ 'background-color': themeColor }">发送</button>
      </div>
      <emotion @emotion="handleEmotion" :height="200" v-show="emojiVis"></emotion>
    </div>

    <!-- 移动端输入 -->
    <div class="chat-h5-input-panel">
      <div class="chat-input-editor" @click="emojiVis = false">
        <DivEditable class="edior" v-model="msg" :placeholder="placeholder"></DivEditable>
        <div style="display: flex" v-if="props.showType === 2">
          <img class="icon" src="@/assets/images/h5/emoji.png" @click.stop="emojiVis = !emojiVis" />
          <el-icon class="icon" v-if="msg == '' && !moreToolVis" @click="moreToolVis = true">
            <CirclePlus />
          </el-icon>
          <el-icon class="icon" v-if="msg == '' && moreToolVis" @click="moreToolVis = false">
            <CircleClose />
          </el-icon>
        </div>
        <button
          v-if="msg != '' || props.showType === 1"
          class="send-btn"
          @click="sendMsg(1)"
          :style="{ 'background-color': themeColor, marginLeft: props.showType === 1 ? '10px' : '' }"
        >
          发送
        </button>
      </div>
      <div class="chat-input-tool" v-show="moreToolVis">
        <div class="tool-item" @click="fileUpload(3)">
          <img class="img" src="@/assets/images/h5/takephoto.png" />
          <div class="text">图片</div>
        </div>
        <div class="tool-item" @click="fileUpload(4)">
          <img class="img" src="@/assets/images/h5/file.png" />
          <div class="text">文件</div>
        </div>
        <div class="tool-item" @click="commentClick">
          <img class="img" src="@/assets/images/h5/appraice.png" />
          <div class="text">评价</div>
        </div>
        <div class="tool-item" v-for="(item, index) in shortcuts" :key="index" @click="custFunc(item)">
          <img class="img" :src="item.icon" />
          <div class="text">{{ item.name }}</div>
        </div>
        <div class="tool-item" @click="quitClick">
          <img class="img" src="@/assets/images/h5/out.png" />
          <div class="text">退出</div>
        </div>
      </div>
      <emotion class="emoji" @emotion="handleEmotion" :height="200" v-show="emojiVis"></emotion>
    </div>

    <!-- 文件上传 -->
    <el-upload
      :action="uploadUrl"
      :multiple="true"
      v-show="false"
      :before-upload="fileUploadBefore"
      :on-success="fileUploadSuccess"
    >
      <div ref="uploadRef"></div>
      >
    </el-upload>

    <!-- 禁用层 -->
    <div class="error-mask" v-if="props.showType === 2 && sessionStatus != 2">
      <span v-if="sessionStatus == 0">
        会话已结束，
        <span class="link" @click="sessionOpenClick">点击此处</span>
        开启会话
      </span>
      <span v-if="sessionStatus == 1">
        您正在排队中，
        <span class="link" @click="leaveMsgClick">点击此处</span>
        给我们留言
      </span>
      <span v-if="sessionStatus == 3">
        当前不在服务时间内，
        <span class="link" @click="leaveMsgClick">点击此处</span>
        给我们留言
      </span>
    </div>
  </div>
</template>
<script setup>
import DivEditable from '../../components/DivEditable.vue'
import Emotion from '../../components/Emotion/index'
import { ElMessage } from 'element-plus'
import { CirclePlus, CircleClose } from '@element-plus/icons-vue'
import { defineProps, ref, getCurrentInstance, computed, defineEmits, watch } from 'vue'
const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => {}
  },
  sessionStatus: {
    type: Number
  },
  orgId: {
    type: String
  },
  showType: {
    type: Number,
    default: 1
  }
})
const { proxy } = getCurrentInstance()
const msg = ref('')
const emojiVis = ref(false)
const moreToolVis = ref(false)
const uploadUrl = ref(`${proxy.$config.baseURL}/auth-server/app/common/upload`)
const uploadType = ref(1)
// 白名单
const whiteList = ref([])
const config = computed(() => {
  if (props.styleConfig) {
    return props.styleConfig
  } else {
    return {
      themeColor: '#3571F0',
      placeholder: '请输入内容...',
      pageTitle: '',
      navigatorContent: { text: '' }
    }
  }
})
const themeColor = computed(() => {
  return config.value.themeColor
})
const placeholder = computed(() => {
  return config.value.placeholder
})
const shortcuts = computed(() => {
  return config.value.shortcuts || []
})
const emit = defineEmits(['sendMsg', 'comment', 'quit', 'reconnnect', 'leaveMsg'])
const sendMsg = (type, content, url) => {
  let result = {}
  result.type = type
  if (type == 1 && msg.value != '' && msg.value != ' ') {
    if (filterWhite(msg.value)) {
      result.content = msg.value
      result.url = ''
      msg.value = ''
      emit('sendMsg', result)
    } else {
      ElMessage.info('消息发送失败(不能发送白名单以外的超链接)')
    }
  } else if (content && content != ' ') {
    result.content = content
    result.url = url
    emit('sendMsg', result)
  } else {
    ElMessage.info('请输入内容')
  }
}
const handleEmotion = (i) => {
  msg.value = `${msg.value}<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${i}.gif" align="top">`
}
// 上传触发
const uploadRef = ref(null)
const fileUpload = (type) => {
  uploadType.value = type
  uploadRef.value.click()
}
// 文件上传之前校验
const fileUploadBefore = (file) => {
  if (uploadType.value == 3 && 'image/jpg|image/jpeg|image/png|image/gif'.indexOf(file.type) == -1) {
    ElMessage.error('图片格式错误')
    return false
  }
}
// 上传成功
const fileUploadSuccess = (response) => {
  let content = {
    name: response.data.fileName,
    url: response.data.fileUrl,
    size: response.data.fileSize
  }
  let url = response.data.fileUrl
  sendMsg(uploadType.value, JSON.stringify(content), url)
}
// 评价
const commentClick = () => {
  moreToolVis.value = false
  emit('comment')
}
// 退出
const quitClick = () => {
  moreToolVis.value = false
  emit('quit')
}
// 自定义功能
const custFunc = (func) => {
  if (func.method == 1 && func.url) {
    // 外部链接
    window.location.href = func.url
  } else {
    ElMessage.info('该功能暂未开通')
  }
}
// 重启会话
const sessionOpenClick = () => {
  emit('reconnnect')
}
// 开启留言
const leaveMsgClick = () => {
  emit('leaveMsg')
}
const filterWhite = (content) => {
  if (content.includes('https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/')) {
    // 表情包
    return true
  }
  if (/^https?:\/\//.test(content)) {
    if (whiteList.value.length > 0 && whiteList.value.some((item) => item.includes(content))) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
watch(
  () => props.orgId,
  (newVal) => {
    if (newVal) {
      // 获取白名单链接
      proxy.$http.get('/cs/app/whiteListAll', { params: { orgId: props.orgId } }).then(({ data }) => {
        if (data.code == 0) {
          whiteList.value = data.data.data.map((i) => i.whiteAddr)
        } else {
          ElMessage.error(data.msg)
        }
      })
    }
  },
  { immediate: true }
)
</script>
<style scoped lang="less">
.chat-input {
  position: relative;

  .chat-pc-input-panel {
    background: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    .chat-input-tool {
      height: 40px;
      border-bottom: 1px solid #eee;
      padding: 0 10px;
      display: flex;
      align-items: center;

      .tool-item {
        margin-right: 15px;
        cursor: pointer;
      }
    }

    .chat-input-editor {
      position: relative;

      .edior {
        height: 100px;
        overflow: auto;
        padding: 10px;
        width: auto;
      }

      .send-btn {
        position: absolute;
        right: 15px;
        bottom: 15px;
        background: #3571f0;
        color: #fff;
        border: none;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }
  }

  .chat-h5-input-panel {
    display: none;
    background: #f6f6f6;

    .chat-input-editor {
      display: flex;
      flex: 1;
      align-items: flex-end;
      padding: 10px;
      line-height: 30px;
      border-bottom: 1px solid #eee;

      .edior {
        background-color: #fff;
        padding: 0 10px;
        max-height: 100px;
        overflow-y: auto;
      }

      .icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin: 4px 6px;
        color: rgb(100, 101, 109);
      }

      .send-btn {
        background: #3571f0;
        color: #fff;
        border: none;
        font-size: 14px;
        border-radius: 4px;
        width: 90px;
        height: 30px;
        text-align: center;
      }
    }

    .chat-input-tool {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      height: 200px;
      padding: 10px;

      .tool-item {
        width: 25%;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
        margin-bottom: 20px;

        .img {
          background-color: #fff;
          border-radius: 10px;
          padding: 15px;
          box-sizing: border-box;
          width: 54px;
          height: 54px;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        }

        .text {
          font-size: 14px;
          color: #5a5b64;
          margin-top: 3px;
        }
      }
    }

    .emoji {
      /deep/ .emotion-box {
        position: static;
        width: 100%;
        background-color: #f6f6f6;
        border: none;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .chat-pc-input-panel {
      display: none;
    }

    .chat-h5-input-panel {
      display: block;
    }
  }

  .error-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 12px;
    .link {
      color: #3571f0;
      cursor: pointer;
    }
    .important {
      color: #f00;
    }
  }
}
</style>
