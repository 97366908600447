<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script setup>
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
html,body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #babbbf;
}
</style>
